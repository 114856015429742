


export const API_URL="http://fnb.backend.looquplab.com"


// export const API_URL="http://20.83.166.134:9018"
//looqup demo
// export const API_URL="http://20.42.88.81:9011"
// export const API_URL="http://0.0.0.0:9011"

export const SECRET_KEY = "Y29uc3VtZXJfZm5iX3BvYw=="

export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL
